import Stats from './Index.vue'
import APIPlayers from './APIPlayers'

export default {
  path: '/stats',
  name: 'stats',
  component: Stats,
  meta: {
    menu_title: 'Statistics',
    icon: 'mdi-chart-bar'
  },
  children: [
    {
      path: 'api-players',
      name: 'apiplayers',
      component: APIPlayers,
      meta: {
        menu_title: 'API Players',
        icon: 'mdi-animation-play'
      }
    }
  ]
}
