<template>
  <v-container fluid fill-height style="min-height:80vh;background-color: white;">
    <v-row justify-center align-center>
      <img src="@/assets/images/logo.png" width="400">
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Home',
  components: {}
}
</script>
