<template>
  <div>
    <server-formset
      title="Opinions"
      :data-url="urlPath"
      :header-actions="['refresh', 'create']"
      ref="opinions"
      ></server-formset>
  </div>
</template>

<script>
import ServerFormset from '@/modules/crud/components/ServerFormset.vue'
export default {
  components: {
    ServerFormset
  },
  props: [
    'albumId'
  ],
  computed: {
    urlPath () {
      return `djque/album/${this.albumId}/opinion`
    }
  },
  data () {
    return {}
  }
}
</script>

<style>

</style>
