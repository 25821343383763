const state = {
  loading: false,
  alert: {
    show: false,
    color: '',
    text: '',
    icon: ''
  }
}

export default state
