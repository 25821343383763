<template>
  <div>
    <view-layout :title="dialogTitle">
      <template v-slot:title>
        <v-row>
          <v-col cols="8">
            <div class="caption">Artist</div>
            <h4 class="title">{{ values.name }}</h4>
          </v-col>
          <v-col cols="4">
            <v-row justify="end">
              <icon-button
                icon='mdi-pencil'
                :tooltip="$t('global.datatable.buttons.edit')"
                color="orange"
                dark
                @clicked="onEdit"
                ></icon-button>
            </v-row>
          </v-col>
        </v-row>
      </template>
      <template v-slot:default>
        <v-row>
          <v-col cols="4">
            <v-img
              :src="values['photocdnid']"
              />
          </v-col>
          <v-col cols="8">
            <form-view
              :fields="fields"
              :values="values"
              :exclude="['name', 'photocdnid']"
              ></form-view>
          </v-col>
        </v-row>
      </template>
      <template v-slot:right_side>
        <v-card flat>
          <v-card-text>
            <server-table v-if="childDataUrl"
              :data-url="childDataUrl"
              detailName="album-edit"
              :detail-url="'/music/albums/%s'"
              :actions="['view']"
              ref="child_table"
              >
            </server-table>
          </v-card-text>
        </v-card>
      </template>
    </view-layout>
    <form-dialog
      name="artist-edit"
      :data-url="editUrl"
    ></form-dialog>
    <form-dialog
      name="album-edit"
      :data-url="albumEditUrl"
    ></form-dialog>
  </div>
</template>

<script>
import { format } from 'util'
import { mapMutations } from 'vuex'
import IconButton from '@/modules/app/components/IconButton.vue'
import ViewLayout from '@/modules/crud/components/ViewLayout'
import ServerTable from '@/modules/crud/components/ServerTable'
import FormView from '@/modules/crud/components//FormView'
import FormDialog from '@/modules/crud/components/FormDialog.vue'
import FormMixin from '@/modules/crud/components/mixins/form-instance.js'
export default {
  name: 'ArtistView',
  components: {
    FormDialog,
    FormView,
    IconButton,
    ServerTable,
    ViewLayout
  },
  mixins: [
    FormMixin
  ],
  data () {
    return {
      name: 'artist-view',
      dataUrl: 'music/artistview',
      itemKey: null,
      editUrl: 'music/artistedit',
      childDataUrl: null,
      albumsUrl: 'music/artistalbums/%s',
      albumEditUrl: 'music/albumedit'
    }
  },
  computed: {
    dialogTitle () {
      return this.title || this.$t('global.app.music.artist')
    }
  },
  mounted () {
    this.itemKey = this.$route.params.id
    this.getOptions(true)
    this.childDataUrl = format(this.albumsUrl, this.itemKey)
    // refresh child table
    /* this.$nextTick(function () {
      this.$refs.child_table.init()
    }) */
  },
  methods: {
    ...mapMutations('crud', ['openModal', 'setModalKey']),
    onEdit () {
      this.setModalKey({ name: 'artist-edit', value: this.itemKey })
      this.openModal('artist-edit')
    }
  }
}
</script>

<style>

</style>
