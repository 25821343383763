<template>
  <div>
    <server-table
      title="New Albums"
      :data-url="dataUrl"
      :detail-name="detailName"
      :detail-url="detailUrl"
      :actions="actions"
    ></server-table>
  </div>
</template>

<script>
import ServerTable from '@/modules/crud/components/ServerTable.vue'
export default {
  components: {
    ServerTable
  },
  data () {
    return {
      dataUrl: 'djque/newalbum',
      detailName: 'album-view',
      detailUrl: '/music/albums/%s',
      actions: ['view']
    }
  }
}
</script>
