<template>
  <v-dialog v-model="show" persistent max-width="1200">
    <template v-slot:activator="{ on }">
      <!-- icon-button
        icon='mdi-magnify'
        v-on="on"
        :tooltip="$t('global.form.buttons.search')"
        color="white"
      ></icon-button -->
      <v-icon left v-on="on">mdi-magnify</v-icon>
      <!-- v-btn color="blue" v-on="on" class="white--text">
        <v-icon left>mdi-album</v-icon>
        {{ $t('global.app.upload.choose_album') }}
      </v-btn -->
    </template>
    <v-card>
      <v-card-title class="headline">
        <span>{{ $t('global.app.upload.find_album') }}</span>
        <v-spacer></v-spacer>
        <icon-button icon='mdi-close' @clicked="show=false" :tooltip="$t('global.form.buttons.close')"></icon-button>
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-row>
            <v-col cols="4">
              <v-text-field
                label="Artist"
                v-model='artist_search'
                clearable
                @input="clear_artist_selection"
                @keyup.enter="search"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                label="Album"
                v-model='album_search'
                clearable
                @input="clear_album_selection"
                @keyup.enter="search"
              ></v-text-field>
            </v-col>
            <v-col cols="4" class="d-flex align-center">
              <icon-button
                icon="mdi-magnify"
                :tooltip="$t('global.app.upload.search')"
                @clicked="search"
                ></icon-button>
            </v-col>
          </v-row>
        </v-form>

        <v-row>
          <v-col cols="6">
            <v-card v-if="artistFound">
              <v-card-text>
                <v-list subheader v-if="artists.length">
                  <v-subheader>{{ $t('global.app.upload.found_artists') }}</v-subheader>
                  <v-list-item-group>
                    <template v-for="artist in filteredArtists">
                      <v-list-item
                        :key="artist.uid"
                        @change="select_artist(artist.uid)"
                        :class="{ selected: artist.uid === selectedArtist.uid }"
                      >
                        <v-list-item-avatar left v-if="artist.avatar">
                          <v-img :src="artist.avatar"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>{{ artist.name }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                          <flag
                            :code="artist.country"
                            :name="artist.country_name"
                            size="small" />
                        </v-list-item-icon>
                        <v-list-item-action>
                          <v-checkbox
                            v-model="choosenArtist"
                            :value="artist.uid"
                            @click.stop="choose_artist(artist.uid)"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-list-item-group>
                </v-list>
                <h4 v-else>{{ $t('global.app.upload.no_artist') }}</h4>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card v-if="albumFound">
              <v-card-text>
                <v-list subheader v-if="albums.length">
                  <v-subheader>{{ $t('global.app.upload.found_albums') }}</v-subheader>
                  <v-list-item-group>
                    <template v-for="album in artistAlbums">
                      <v-list-item
                        :key="album.uid"
                        @click="select_album(album.uid)"
                        :class="{ selected: album.uid === selectedAlbum.uid }"
                      >
                        <v-list-item-avatar left v-if="album.avatar">
                          <v-img :src="album.avatar"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>{{ album.title }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                          <v-chip color="secondary">
                            {{ album.tracks_count }}
                          </v-chip>
                        </v-list-item-icon>
                        <v-list-item-action>
                          <v-checkbox
                            readonly
                            v-model="choosenAlbum"
                            :value="album.uid"
                          ></v-checkbox>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-list-item-group>
                </v-list>
                <h4 v-else>{{ $t('global.app.upload.no_album') }}</h4>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn class="orange--text darken-1" text right @click.native="onCancel">Cancel</v-btn>
        <v-btn class="green--text darken-1" text right @click.native="onConfirm" v-if="!!selectedAlbum.uid">Confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import iconButton from '@/modules/app/components/IconButton'
import Flag from '@/modules/app/components/CountryFlag.vue'

export default {
  name: 'UploadAlbumSearch',
  components: {
    iconButton,
    Flag
  },
  data () {
    return {
      show: false,
      artist_search: '',
      album_search: '',
      artistFound: false,
      albumFound: false,
      artists: [],
      albums: [],
      filteredArtists: [],
      artistAlbums: [],
      selectedArtist: {},
      selectedAlbum: {},
      choosenAlbum: [],
      choosenArtist: []
    }
  },
  computed: {
    ...mapState('music', [
      'upAlbum'
    ]),
    searchParams () {
      return {
        artist: this.artist_search || null,
        album: this.album_search || null,
        artist_uid: this.selectedArtist.uid || null
      }
    }
  },
  watch: {
    choosenArtist (value) {
      if (value.length === 0) {
        this.choosenAlbum = []
      }
      this.filter_artists()
    },
    choosenAlbum (value) {
      this.filter_albums()
    }
  },
  methods: {
    ...mapActions('music', ['uploadSearch']),
    reset_results () {
      this.artists = []
      this.filteredArtists = []
      this.clear_artist_selection()
      this.reset_albums_results()
    },
    reset_albums_results () {
      this.albums = []
      this.artistAlbums = []
      this.clear_album_selection()
    },
    clear_artist_selection () {
      this.selectedArtist = {}
      this.choosenArtist = []
      this.clear_album_selection()
    },
    clear_album_selection () {
      this.selectedAlbum = {}
      this.choosenAlbum = []
      // this.reset_albums_results()
    },
    search () {
      if (this.artist_search && !this.selectedArtist.uid) {
        this.artistFound = false
        this.uploadSearch({ action: 'artists-search', search: this.searchParams }).then((data) => this.setArtistData(data))
      }
      if (this.album_search || this.artist_search || this.selectedArtist.uid) {
        if (!this.artist_search) {
          this.artistFound = false
          this.artists = []
        }
        this.albumFound = false
        this.uploadSearch({ action: 'albums-search', search: this.searchParams }).then((data) => this.setAlbumData(data))
      }
    },
    setArtistData (data) {
      this.artistFound = true
      this.artists = data.results
      this.filter_artists()
    },
    setAlbumData (data) {
      this.albumFound = true
      this.albums = data.results
      this.filter_albums()
    },
    select_artist (uid) {
      if (this.selectedArtist.uid === uid) {
        // clear selected artist only if not choosen
        if (this.choosenArtist.length === 0) {
          this.clear_artist_selection()
        }
      } else {
        const selected = this.artists.filter((artist) => artist.uid === uid)[0]
        this.selectedArtist = {
          uid: selected.uid,
          name: selected.name
        }
      }
      this.search()
    },
    choose_artist (uid) {
      const choosen = this.choosenArtist[0]
      console.log('choose artist:', choosen, '->', uid)
      if (choosen === uid) {
        this.choosenArtist = []
      } else {
        this.choosenArtist = [uid]
        if (this.selectedArtist.uid !== uid) {
          this.select_artist(uid)
        }
      }
    },
    filter_artists () {
      this.filteredArtists = this.artists.filter((artist) => this.choosenArtist.length ? artist.uid === this.choosenArtist[0] : true)
      console.log('filtered artists:', this.filteredArtists.length)
    },
    filter_albums () {
      if (this.choosenAlbum.length) {
        this.artistAlbums = this.albums.filter((album) => album.uid === this.choosenAlbum[0])
      } else {
        this.artistAlbums = this.albums.filter((album) => this.selectedArtist.uid ? album.artist_uid === this.selectedArtist.uid : true)
      }
      // this.artistAlbums.sort((a, b) => a.album_title < b.album_title ? -1 : a.album_title > b.album_title ? 1 : 0)
      console.log('artist albums:', this.artistAlbums.length)
    },
    select_album (uid) {
      if (this.choosenAlbum.length && this.choosenAlbum[0] === uid) {
        // check off
        this.choosenAlbum = []
        this.selectedAlbum = {}
      } else { // if (!this.choosenAlbum.length || this.choosenAlbum[0] !== uid)
        this.choosenAlbum = [uid]
        const selected = this.albums.filter((album) => album.uid === uid)[0]
        this.selectedAlbum = {
          uid: selected.uid,
          title: selected.title
        }
        if (!this.selectedArtist.uid || this.selectedArtist.uid !== selected.artist_uid) {
          this.selectedArtist = {
            uid: selected.artist_uid,
            name: selected.artist_name
          }
        }
        if (!this.choosenArtist.length || this.choosenArtist[0] !== selected.artist_uid) {
          this.$set(this.choosenArtist, 0, selected.artist_uid)
        }
      }
      this.filter_albums()
    },
    onCancel () {
      this.reset_results()
      this.show = false
    },
    onConfirm () {
      if (this.selectedAlbum.uid) {
        this.show = false
        this.$emit('confirm', this.selectedAlbum.uid)
      }
    }
  }
}
</script>

<style scoped>
  .v-list-item.v-list-item--active {
    background-color: transparent;
  }
  .v-list-item.selected {
    background-color: pink;
  }
</style>
