<template>
  <div>
    <v-row>
      <v-col cols="6">
        <v-menu
          v-model="menu_from"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="month_from"
              label="Month from"
              prepend-icon="mdi-calendar-range"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="month_from" type="month" @input="menu_from = false"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="6">
        <v-menu
          v-model="menu_to"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="month_to"
              label="Month to"
              prepend-icon="mdi-calendar-range"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="month_to" type="month" @input="menu_to = false"></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  data () {
    return {
      menu_from: false,
      menu_to: false,
      month_from: new Date().toISOString().substr(0, 7),
      month_to: new Date().toISOString().substr(0, 7)
    }
  },
  watch: {
    month_from (val) {
      this.update()
    },
    month_to (val) {
      this.update()
    }
  },
  created () {
    // console.log('Picker created: ', this.month_from, ' - ', this.month_to)
    this.update()
  },
  methods: {
    update () {
      this.$emit('month_range_changed', [
        this.month_from,
        this.month_to
      ])
    }
  }
}
</script>
