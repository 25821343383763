<template>
  <div>
    <controls
      :title="title || tableTitle"
      :show-search="options.searchFilter"
      :actions="headerActions"
      @updateSearch="updateSearch"
      @refresh="refreshTable"
    >
    </controls>
    <v-data-table
      :headers="headers"
      :items="tableItems"
      :options="pagination"
      :server-items-length="totalItems"
      :loading="loading"
      :footer-props="footerProps"
      light
      multi-sort
      dense
      @update:options="onUpdatePagination"
    >
      <template
        v-for="(header, i) in headers"
        v-slot:[`item.${header.value}`]="{ item }"
      >
        <span :key="i">
          <action-buttons
            v-if="header.value==='actions'"
            :actions="actions"
            :custom-actions="customActions"
            @edit="onEdit(item)"
            @delete="onDelete(item)"
            @view="onView(item)"
            ></action-buttons>
          <table-cell
            v-else
            :options="header"
            :value="item[header.value]"
          >
          </table-cell>
        </span>
      </template>
    </v-data-table>
    <confirm-dialog
      :dialog="confirmDelete"
      :dialogTitle="$t('global.alerts.confirmDeleteTitle')"
      :dialogText="confirmDeleteText"
      @onConfirm="onConfirmDelete"
      @onCancel="onCancelDelete"
      ></confirm-dialog>
  </div>
</template>
<script>
import {
  mapMutations,
  mapActions,
  mapState
} from 'vuex'
import { format } from 'util'
import apiConfig from '@/config/api'
import { objectUpdate, compareObjects } from '@/helpers'
import Controls from './Controls'
import ActionButtons from './ActionButtons'
import ConfirmDialog from './ConfirmDialog.vue'
import TableCell from './TableCell.vue'

export default {
  name: 'ServerTable',
  components: {
    Controls,
    ActionButtons,
    ConfirmDialog,
    TableCell
  },
  props: {
    title: String,
    dataUrl: String,
    detailName: String,
    detailUrl: String,
    actions: {
      type: [Array, null],
      default: () => ['edit', 'delete']
    },
    customActions: {
      type: Object,
      default: () => {}
    },
    headerActions: {
      type: [Array, null],
      default: () => ['refresh']
    }
  },
  data () {
    return {
      pagination: {
        page: 1,
        itemsPerPage: 25,
        sortBy: [],
        sortDesc: [],
        multiSort: true,
        mustSort: false
      },
      options: {},
      tableTitle: null,
      headers: [],
      tableItems: [],
      totalItems: null,
      primaryField: null,
      search: null,
      showActions: false,
      confirmDelete: false,
      confirmDeleteText: ''
    }
  },
  computed: {
    ...mapState(['loading']),
    pkField () {
      return this.options.pkField
    },
    footerProps () {
      return {
        'items-per-page-options': this.options.itemsPerPageOptions || [15, 25, 50, -1]
      }
    }
  },
  mounted: function () {
    this.init()
  },
  methods: {
    ...mapMutations('crud', [
      'setModalKey',
      'openModal'
    ]),
    ...mapActions(['openAlertBox']),
    init () {
      // get table config from server
      this.getTableData({ onlyConfig: true }).then((data) => {
        this.setConfig(data)
        this.refreshTable()
      })
    },
    refreshTable () {
      const params = Object.assign({}, this.pagination)
      params.search = this.search
      this.getTableData(params).then((data) => {
        this.tableItems = data.data
        this.totalItems = data.total
      })
    },
    setConfig (data) {
      if ('config' in data) {
        const config = data.config
        this.options = config.options
        objectUpdate(this.pagination, this.options)

        this.primaryField = this.options.primaryField
        this.tableTitle = this.options.caption

        const headers = config.headers

        if ((!!this.customActions && Object.keys(this.customActions).length > 0) ||
            (!!this.actions && this.actions.length > 0)) {
          // add actions header
          headers.push({
            text: '',
            value: 'actions',
            sortable: false
          })
          this.showActions = true
        }
        this.headers = headers
      }
    },
    getTableData (params) {
      return new Promise((resolve, reject) => {
        this.$api.post(this.dataUrl, {
          prefix: apiConfig.prefix.tables,
          data: params
        }).then((response) => {
          resolve(response.data)
        })
      })
    },
    updateSearch (search) {
      this.search = search
      this.pagination.page = 1
      this.refreshTable()
    },
    onEdit (item) {
      if (!this.primaryField || item[this.primaryField] === undefined) {
        this.openAlertBox(['alertError', 'No primary key in row data.'])
      } else {
        this.setModalKey({ name: this.detailName, value: item[this.primaryField] })
        this.openModal(this.detailName)
      }
    },
    onView (item) {
      if (!this.primaryField || item[this.primaryField] === undefined) {
        this.openAlertBox(['alertError', 'No primary key in row data.'])
      } else {
        const url = format(this.detailUrl, item[this.primaryField])
        console.log('redirect to', url)
        this.$router.push(url)
      }
    },
    onDelete (item) {
      this.confirmDeleteText = this.$t('global.alerts.confirmDeleteText', {
        obj: 'this item'
      })
      this.confirmDelete = true
    },
    onConfirmDelete () {
      console.log('Deleting')
      // TODO: delete
      this.confirmDelete = false
    },
    onCancelDelete () {
      this.confirmDelete = false
    },
    onUpdatePagination (value) {
      if (!this.loading) {
        if (!compareObjects(this.pagination, value)) {
          this.pagination = value
          this.refreshTable()
        }
      }
    }
  }
}
</script>
