<template>
  <span class="text-no-wrap">
    <template v-for="(action, name) in filtered_actions">
      <icon-button
        v-bind="action"
        :key="name"
        @clicked="clicked(name)"
      ></icon-button>
    </template>
  </span>
</template>

<script>
import IconButton from '@/modules/app/components/IconButton.vue'

export default {
  components: {
    IconButton
  },
  props: {
    // array of action names
    actions: {
      type: Array,
      default: () => []
    },
    // custom actions specification
    customActions: {
      type: Object,
      default: () => {}
    },
    modifiers: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      defaultActions: {
        refresh: {
          icon: 'mdi-refresh',
          color: 'blue',
          tooltip: this.$t('global.datatable.buttons.refresh')
        },
        create: {
          icon: 'mdi-plus-thick',
          color: 'green',
          tooltip: this.$t('global.form.buttons.create')
        },
        save: {
          icon: 'mdi-check-circle',
          color: 'green',
          tooltip: this.$t('global.form.buttons.save')
        },
        view: {
          icon: 'mdi-eye',
          color: 'info',
          tooltip: this.$t('global.form.buttons.view')
        },
        edit: {
          icon: 'mdi-pencil',
          color: 'orange',
          tooltip: this.$t('global.datatable.buttons.edit')
          // route: { name: 'Artist', params: { id: row => row.id } },
          // props: { path: 'music/artist', pk: row => row.id }
        },
        delete: {
          icon: 'mdi-delete',
          color: 'red',
          tooltip: this.$t('global.datatable.buttons.delete')
        }
      }
    }
  },
  computed: {
    filtered_actions () {
      // filter given actions out of default ones + add custom ones
      const result = Object.assign(
        Object.fromEntries(
          Object.entries(this.defaultActions).filter((tuple) => this.actions.includes(tuple[0]))
        ),
        this.customActions
      )
      // add modifiers to actions if defined
      this.modifiers.split(' ').forEach((modifier) => {
        const value = modifier.trim()
        if (value.length) {
          Object.keys(result).forEach((k) => {
            result[k][modifier] = true
          })
        }
      })
      return result
    }
  },
  methods: {
    clicked (name) {
      // pass the event upward
      this.$emit('clicked', name)
      this.$emit(name)
    }
  }
}
</script>

<style>

</style>
