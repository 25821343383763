<template>
  <div
    class="
      card-title
      table-controls
      px-3
      d-flex
      align-center
      justify-space-between
    "
  >
    <!-- left side -->
    <div>
      <span class="title">{{ title }}</span>
    </div>

    <!-- center side -->
    <div>
      <!-- Search in table -->
      <span
        v-if="showSearch"
        class="crud-controls__search"
      >
        <v-text-field
          v-model="search"
          :label="$t('global.datatable.search')"
          class="crud-controls__search-input"
          append-icon="mdi-magnify"
          single-line
          hide-details
          clearable
          @input="updateSearch"
        ></v-text-field>
      </span>
    </div>

    <!-- right side -->
    <div>
      <template v-if="showPrevNext">
        <icon-button icon='mdi-chevron-left' @clicked="$emit('previous')" :tooltip="$t('global.form.buttons.previous')" color="white"></icon-button>
        <icon-button icon='mdi-chevron-right' @clicked="$emit('next')" :tooltip="$t('global.form.buttons.next')" color="white"></icon-button>
      </template>

      <action-buttons
        :actions="actions"
        :custom-actions="customActions"
        modifiers="large dark"
        @clicked="clicked"
      ></action-buttons>

      <!-- Export data  -->
      <!-- TODO: convert to customAction -->
      <!-- icon-button
        v-if="exportButton"
        :loading="excelLoading"
        :tooltip="$t('global.datatable.buttons.copyToExcel')"
        color="green darken-4"
        icon="mdi-microsoft-excel"
        large
        @clicked="$emit('exportToExcel')"
      / -->
    </div>
  </div>
</template>

<script>
import IconButton from '@/modules/app/components/IconButton'
import ActionButtons from './ActionButtons'
export default {
  name: 'Controls',
  components: {
    IconButton,
    ActionButtons
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    showSearch: {
      type: Boolean,
      default: false
    },
    initialSearch: {
      type: String,
      default: ''
    },
    showPrevNext: {
      type: Boolean,
      default: false
    },
    actions: {
      type: Array,
      default: () => []
    },
    // custom actions specification
    customActions: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      search: this.initialSearch,
      defaultActions: {

      }
    }
  },
  methods: {
    updateSearch () {
      this.$emit('updateSearch', this.search)
    },
    clicked (name) {
      // pass the event upward
      this.$emit('clicked', name)
      this.$emit(name)
    }
  }
}
</script>

<style lang="scss" scoped>
.table-controls {
  margin-bottom: 2rem;
}
.crud-controls {
  &__search {
    margin: 0 15px;
    display: inline-block;
    width: 120px;
    @media (min-width: 500px) {
      width: 250px;
    }
  }
  &__search-input {
    margin-top: -8px;
  }
  &__select-statuses {
    margin: 0 15px;
    display: inline-block;
    width: 120px;
    @media (min-width: 500px) {
      width: 250px;
    }
  }
}
</style>
