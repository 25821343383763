<template>
  <div class="px-6">
    <v-row>
      <v-col cols="6">
        <month-picker @month_range_changed="update_range"></month-picker>
        <crud-tree
          :prefix="prefix"
          :data-url="treeUrl"
          @update_active="update_active"
        ></crud-tree>
      </v-col>
      <v-col cols="6">
        <counts-table
          :count="total_count"
          :size="total_size"
          :timestamp="timestamp"
          :items="values"
        ></counts-table>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <result-chart
          :count="total_count"
          :size="total_size"
          :items="values"
          label_key="month"
          value_key="count"
          class="mt-6"
        ></result-chart>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MonthPicker from '@/modules/stats/MonthPicker'
import CrudTree from '@/modules/stats/CrudTree'
import CountsTable from '@/modules/stats/CountsTable'
import ResultChart from '@/modules/stats/ResultChart'

export default {
  components: {
    MonthPicker,
    CrudTree,
    CountsTable,
    ResultChart
  },
  data () {
    return {
      prefix: 'tables',
      treeUrl: 'monthstats/localitygroup',
      dataUrl: 'monthstats/api-players',
      menu: false,
      month_from: '',
      month_to: '',
      active: {},
      results: []
    }
  },
  computed: {
    params () {
      return {
        month_from: this.month_from,
        month_to: this.month_to,
        model: this.active.model,
        id: this.active.id
      }
    },
    total_count () {
      return this.results.count
    },
    total_size () {
      return this.results.size
    },
    timestamp () {
      return this.results.timestamp
    },
    values () {
      return this.results.values
    }
  },
  methods: {
    update_range (monthRange) {
      console.log('Refreshed')
      this.month_from = monthRange[0]
      this.month_to = monthRange[1]
      // this.get_data()
    },
    update_active (val) {
      if (!!val && val.length > 0) {
        const active = val[0]
        this.active = {
          name: active.name,
          id: active.id,
          model: active.model
        }
        this.get_data()
      } else {
        console.log('Empty active')
      }
    },
    get_data () {
      const data = this.params
      if (!data.model || !data.id || data.month_from > data.month_to) {
        console.log('Warning Wrong params', data)
        return
      }

      return new Promise((resolve, reject) => {
        this.$api.post(this.dataUrl, {
          prefix: this.prefix,
          data: data
        })
          .then((response) => {
            this.results = response.data
          })
      })
    }
  }
}
</script>

<style>

</style>
