<template>
  <div>
    <v-row>
      <v-col cols="2">
        <v-img
          :src="values['image_url']"
          />
      </v-col>
      <v-col cols="4">
        <div class="caption">Artist</div>
        <h4 class="title">{{ values.artist }}</h4>
        <div class="caption">Album</div>
        <h3 class="headline">{{ values.title }}</h3>
      </v-col>
      <v-col cols="4">
        <form-view
          :fields="fields"
          :values="values"
          :exclude="exclude_fields"
          ></form-view>
      </v-col>
      <v-col cols="2">
        <v-row justify="end">
          <icon-button
            icon='mdi-pencil'
            :tooltip="$t('global.datatable.buttons.edit')"
            color="orange"
            dark
            @clicked="onEdit"
            ></icon-button>
        </v-row>
      </v-col>
    </v-row>

    <v-tabs
      v-model="tab"
      background-color="primary"
      dark
    >
      <v-tab>Album tracks</v-tab>
      <v-tab>Opinions</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <server-table
              :data-url="childDataUrl"
              detailName="track-edit"
              ref="child_table"
              v-if="childDataUrl"
              ></server-table>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <opinions v-if="itemKey"
              :album-id="itemKey"
              ></opinions>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <form-dialog
      name="album-edit"
      :dataUrl="editUrl"
    ></form-dialog>
    <form-dialog
      name="track-edit"
      :dataUrl="trackEditUrl"
    ></form-dialog>
  </div>
</template>

<script>
import { format } from 'util'
import { mapMutations } from 'vuex'
import IconButton from '@/modules/app/components/IconButton.vue'
import ServerTable from '@/modules/crud/components/ServerTable'
import FormView from '@/modules/crud/components//FormView'
import FormDialog from '@/modules/crud/components/FormDialog.vue'
import FormMixin from '@/modules/crud/components/mixins/form-instance.js'
import Opinions from '../djque/Opinions'
export default {
  name: 'AlbumView',
  components: {
    FormDialog,
    FormView,
    IconButton,
    ServerTable,
    Opinions
  },
  mixins: [
    FormMixin
  ],
  data () {
    return {
      name: 'album-view',
      dataUrl: 'music/albumview',
      itemKey: null,
      editUrl: 'music/albumedit',
      childDataUrl: null,
      tracksUrl: 'music/albumtracks/%s',
      trackEditUrl: 'music/trackedit',
      exclude_fields: ['title', 'artist', 'image_url'],
      tab: null
    }
  },
  computed: {
    dialogTitle () {
      return this.title || this.$t('global.app.music.album')
    }
  },
  mounted () {
    this.itemKey = this.$route.params.id
    this.getOptions(true)
    this.childDataUrl = format(this.tracksUrl, this.itemKey)
    // refresh child table
    /* this.$nextTick(function () {
      this.$refs.child_table.init()
    }) */
  },
  methods: {
    ...mapMutations('crud', ['openModal', 'setModalKey']),
    onEdit () {
      this.setModalKey({ name: 'album-edit', value: this.itemKey })
      this.openModal('album-edit')
    }
  }
}
</script>

<style>

</style>
