import Index from './Index.vue'
import NewAlbums from './NewAlbums'
import AlbumQue from './AlbumQue'
import Conflicts from './Conflicts'
import Opinions from './Opinions'

export default {
  path: '/djque',
  name: 'djque',
  component: Index,
  meta: {
    menu_title: 'DJ Queue',
    icon: 'mdi-chart-bar'
  },
  children: [
    {
      path: 'new-albums',
      name: 'newalbums',
      component: NewAlbums,
      meta: {
        menu_title: 'New albums',
        icon: 'mdi-album'
      }
    },
    {
      path: 'album-que',
      name: 'albumque',
      component: AlbumQue,
      meta: {
        menu_title: 'Album Queue',
        icon: 'mdi-tray-full'
      }
    },
    {
      path: 'conflicts',
      name: 'conflicts',
      component: Conflicts,
      meta: {
        menu_title: 'Conflicts',
        icon: 'mdi-sync-alert'
      }
    },
    {
      path: 'opinions/:id',
      name: 'opinions',
      component: Opinions
    }
    /* {
      path: 'userstats',
      name: 'userstats',
      component: UserStats,
      meta: {
        menu_title: 'User stats',
        icon: 'mdi-album'
      }
    },
    {
      path: 'problems',
      name: 'problems',
      component: Problems,
      meta: {
        menu_title: 'Problems',
        icon: 'mdi-album'
      }
    } */
  ]
}
