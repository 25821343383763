import Vue from 'vue'
import auth from '@/config/auth'

const baseUrl = auth.base_url
const prefix = auth.prefix

const actions = {
  login ({ commit }, credentials) {
    return new Promise((resolve) => {
      const action = auth.paths.login || 'login'
      Vue.api.post(action, {
        baseUrl,
        prefix,
        data: credentials
      }).then(function (response) {
        commit('login', response.data)
        resolve()
      })
    })
  },
  logout ({ commit }) {
    return new Promise((resolve) => {
      const action = auth.paths.logout || 'logout'
      Vue.api.post(action, { baseUrl, prefix })
        .then(() => {
          commit('logout')
          resolve()
        })
    })
  },
  getUser ({ commit }) {
    return new Promise((resolve) => {
      const action = auth.paths.getUser || 'user'
      Vue.api.get(action, { baseUrl, prefix })
        .then((response) => {
          if ([
            400,
            401,
            403
          ].includes(response.status)) {
            commit('logout')
            // console.log('forbidden user data, logging out')
          } else {
            commit('setUser', response.data)
          }
          resolve()
        })
    })
  }
}

export default actions
