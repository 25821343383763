import Vue from 'vue'

const mutations = {
  setUpAlbumUID (state, uid) {
    Vue.set(state.upAlbum, 'uid', uid)
  },
  setUpAlbum (state, data) {
    state.upAlbum = data
  }
}

export default mutations
