<template>
  <v-card flat class="elevation-1">
    <v-card-title>
      <v-row align="center" no-gutters>
        <div class="grey--text text-uppercase">
          Total count
        </div>
        <div class="ml-3">
          <span
            class="display-1 font-weight-black"
            v-text="count_fmt"
          ></span>
        </div>
      </v-row>
      <v-row align="center" no-gutters>
        <div class="grey--text text-uppercase">
          Total size
        </div>
        <div class="ml-3">
          <span
            class="display-1 font-weight-black"
            v-text="size_fmt"
          ></span>
        </div>
      </v-row>
      <icon-button
        v-if="items.length"
        :loading="excelLoading"
        :tooltip="$t('global.datatable.buttons.copyToExcel')"
        color="green darken-4"
        icon="mdi-microsoft-excel"
        large
        @clicked="exportToExcel"
      />
    </v-card-title>
    <v-card-text>
      <v-row align="center" no-gutters>
        <span>Calculation timestamp</span>: <span class="ml-1">{{ timestamp }}</span>
      </v-row>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="formatted_items"
      :items-per-page="itemsPerPage"
      class="mt-6"
    >
    </v-data-table>
  </v-card>
</template>

<script>
import IconButton from '@/modules/app/components/IconButton.vue'
import { humanFileSize, formatNumber } from '@/helpers'

export default {
  name: 'CountsTable',
  components: {
    IconButton
  },
  props: {
    count: {
      type: Number,
      default: null
    },
    size: {
      type: Number,
      default: null
    },
    timestamp: {
      type: String,
      default: ''
    },
    items: {
      type: [Array, Function],
      default: () => []
    }
  },
  data: () => ({
    headers: [
      { text: 'Month', value: 'month' },
      { text: 'Count', value: 'count' },
      { text: 'Size', value: 'size' }
    ],
    itemsPerPage: 15,
    excelLoading: false
  }),
  computed: {
    count_fmt () {
      return formatNumber(this.count)
    },
    size_fmt () {
      return humanFileSize(this.size, false)
    },
    formatted_items () {
      if (this.items.length > 0) {
        return this.items.map((item) => {
          item.size = humanFileSize(item.size, false)
          return item
        })
      }
      return []
    }
  },
  methods: {
    exportToExcel () {
      this.excelLoading = true
      const headers = this.headers.map(header => header.text)
      const data = this.formatted_items.map((item) => {
        const row = []
        for (const header of this.headers) {
          row.push(item[header.value])
        }
        return row
      })
      data.push([
        '',
        '',
        ''
      ])
      data.push([
        'Total count',
        '',
        this.count_fmt
      ])
      data.push([
        'Total size',
        '',
        this.size_fmt
      ])

      import('@/helpers/vendor/Export2Excel').then((excel) => {
        this.excelLoading = false
        excel.export_json_to_excel({
          header: headers,
          data,
          filename: this.excelName,
          autoWidth: true,
          bookType: 'xlsx'
        })
      })
    }
  }
}
</script>
