export default {
  home: {
    name: 'Home page'
  },
  music: {
    name: 'Music',
    album: 'Album',
    albums: 'Albums',
    artist: 'Artist',
    artists: 'Artists',
    track: 'Track',
    tracks: 'Tracks'
  },
  upload: {
    choose_album: 'Choose album',
    search: 'Search',
    find_artist: 'Find artist',
    find_album: 'Find album',
    found_artists: 'Found artists',
    found_albums: 'Found albums',
    no_artist: 'No artist found',
    no_album: 'No album found'
  },
  stats: {
    name: 'Statistics',
    api_players: 'API Players'
  },
  admin: {
    name: 'Administration',
    permissions: 'Permissions',
    users: 'Users',
    userPermissions: 'Users - permissions'
  },
  buttons: {
    goback: 'Go back'
  }
}
