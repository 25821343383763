<template>
  <div>
    <sidebar></sidebar>
    <toolbar></toolbar>

    <v-content class="content">
      <v-row v-if="loading" class="default-loader" justify-center align-center>
        <v-progress-linear indeterminate :height="4" color="accent"></v-progress-linear>
      </v-row>
      <v-container fluid fill-height class="main-container" style="align-items:start">
        <v-row style="width:100%">
          <v-col xs12>
            <v-card flat class="main-card">
              <v-card-text class="content-container">

                <router-view style="margin: 0 auto;"></router-view>
                <alert-box></alert-box>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-content>

    <footnote
      v-if="footer"
      color="secondary"
      :dark="true"
    >
    </footnote>
  </div>
</template>

<script>
import Sidebar from '@/modules/app/components/Sidebar.vue'
import Toolbar from '@/modules/app/components/Toolbar.vue'
import AlertBox from '@/modules/app/components/AlertBox.vue'
import Footnote from '@/modules/app/components/Footnote.vue'
// import BackTop from '@/modules/app/components/BackTop.vue'
// import Profile from '@/modules/app/components/Profile.vue'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'app',
  data: () => ({
    profile: true,
    footer: true,
    sidebarItems: []
  }),
  computed: {
    ...mapState(['loading']),
    ...mapGetters('auth', [
      'isLogged',
      'userInfo'
    ]),
    year () {
      const year = new Date()
      return year.getFullYear()
    }
  },
  components: {
    Sidebar,
    Toolbar,
    AlertBox,
    Footnote
    // BackTop,
    // Profile,
  }
}
</script>
