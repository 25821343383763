<template>
  <div>
    <controls
      :title="title"
      :show-search="false"
      :actions="headerActions"
      @refresh="refresh"
      @create="onCreate"
    >
    </controls>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th></th>
            <template v-for="(field, name) in fields">
              <th v-if="!is_hidden(name)" :key="name">{{ field.label }}</th>
            </template>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="index">
            <td>
              <action-buttons
                :actions="['save', 'delete']"
                @save="save(item, index)"
                @delete="onDelete(item)"
              >
              </action-buttons>
            </td>
            <template v-for="(field, name) in fields">
              <td v-if="!is_hidden(name)" :key="name">
                <form-field
                  :name="name"
                  :value="item[name]"
                  :options="field"
                  :errors="resolveError(item[pkField], name)"
                  :reload="reload"
                  :single-line="true"
                  :hide-labels="true"
                  @valueChanged="valueChanged(item, ...arguments)"
                ></form-field>
              </td>
            </template>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <confirm-dialog
      :show="confirmDelete"
      :objIdent="rowIdent"
      :obj="currentItem"
      @onConfirm="onConfirmDelete"
      @onCancel="onCancelDelete"
      ></confirm-dialog>
  </div>
</template>

<script>
import config from '@/config/api'
import Controls from './Controls'
import FormField from './FormField'
import ActionButtons from './ActionButtons'
import ConfirmDialog from './ConfirmDialog.vue'
export default {
  props: {
    title: String,
    dataUrl: String,
    headerActions: Array
  },
  components: {
    Controls,
    FormField,
    ActionButtons,
    ConfirmDialog
  },
  data () {
    return {
      options: null,
      pkField: null,
      items: [],
      fields: [],
      errors: {},
      reload: false,
      currentItem: {},
      confirmDelete: false,
      confirmDeleteText: ''
    }
  },
  computed: {
    rowIdent () {
      if (this.options && this.currentItem.title) {
        return `${this.options.title}: ${this.currentItem.artist} – ${this.currentItem.title}`
      }
      return null
    }
  },
  mounted () {
    this.getData(true)
  },
  methods: {
    is_hidden (fieldName) {
      return this.options.hidden_fields && this.options.hidden_fields.includes(fieldName)
    },
    getData (includeConfig = false) {
      const params = includeConfig ? { includeConfig: includeConfig } : undefined
      this.$api.get(this.dataUrl, { prefix: config.prefix.forms, data: params })
        .then((response) => {
          const data = response.data
          if (data.options !== undefined) {
            this.options = data.options
            this.fields = this.options.fields
            this.pkField = this.options.pkField
            this.items = data.form_data
          } else {
            this.items = data
          }
        })
    },
    refresh () {
      this.getData()
    },
    resolveError (primaryKey, name) {
      const errors = this.errors[primaryKey] || {}
      return errors[name]
    },
    valueChanged (item, name, value) {
      this.$set(item, name, value)
    },
    save (item) {
      const primaryKey = item[this.pkField]
      const urlPath = `${this.dataUrl}/${primaryKey}`
      return new Promise((resolve, reject) => {
        this.$api.put(urlPath, {
          prefix: config.prefix.forms,
          data: item
        }).then((response) => {
          if (response.data.status === 'success') {
            this.$set(this.errors, primaryKey, {})
          }
          resolve(response.data)
        }, (error) => {
          const response = error.response
          if (response.status === 422) {
            this.$set(this.errors, primaryKey, response.data.errors || {})
          } else {
            reject(error)
          }
        })
      })
    },
    onCreate () {
      this.$api.post(this.dataUrl, { prefix: config.prefix.forms })
        .then((response) => {
          this.items = response.data
        })
    },
    onDelete (item) {
      this.currentItem = item
      this.confirmDelete = true
    },
    onConfirmDelete (item) {
      console.log('Deleting')
      const primaryKey = item[this.pkField]
      const urlPath = `${this.dataUrl}/${primaryKey}`

      return new Promise((resolve, reject) => {
        this.$api.delete(urlPath, {
          prefix: config.prefix.forms
        }).then((response) => {
          if (response.status === 204) {
            this.confirmDelete = false
            // refresh formset
            this.refresh()
          }
        })
      })
    },
    onCancelDelete () {
      this.confirmDelete = false
    }
  }
}
</script>

<style>

</style>
