<template>
  <div>
    <server-table
      :data-url="dataUrl"
      :detail-name="detailName"
      editable
      deletable
    >
    </server-table>
    <form-dialog
      :name="detailName"
      :dataUrl="detailUrl"
    ></form-dialog>
  </div>
</template>

<script>
import ServerTable from '@/modules/crud/components/ServerTable.vue'
import FormDialog from '@/modules/crud/components/FormDialog.vue'
export default {
  components: {
    ServerTable,
    FormDialog
  },
  mixins: [],
  data () {
    return {
      dataUrl: 'music/track',
      detailUrl: 'music/trackedit',
      detailName: 'track-edit'
    }
  }
}
</script>
