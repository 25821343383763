import Vue from 'vue'

const mutations = {
  openModal (state, name) {
    Vue.set(state.modalOpen, name, true)
  },
  closeModal (state, name) {
    Vue.set(state.modalOpen, name, false)
  },
  setModalKey (state, { name, value }) {
    Vue.set(state.modalKey, name, value)
  },
  setFormData (state, data) {
    state.formData = data
  },
  setFormDataField (state, { name, value }) {
    Vue.set(state.formData, name, value)
  }
}

export default mutations
