<template>
  <div>
    <v-card>
      <v-tabs
        v-model="mode"
        centered
        light
        icons-and-text
      >
        <v-tabs-slider></v-tabs-slider>

        <v-tab href="#music">
          Music
          <v-icon>mdi-music</v-icon>
        </v-tab>

        <v-tab href="#spots">
          Spots
          <v-icon>mdi-music-circle-outline</v-icon>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="mode">
        <v-tab-item value="music">
          <uploaded-album />
        </v-tab-item>
        <v-tab-item value="spots">
          <v-card flat>
            <v-card-text>{{ mode }}</v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <v-card class="mt-7 pt-5 px-2" >
      <v-file-input
        v-model="files"
        color="deep-purple accent-4"
        counter
        label="File input"
        multiple
        placeholder="Select your files"
        prepend-icon="mdi-paperclip"
        outlined
        @change="upload_files"
        :show-size="1000"
      >
        <template v-slot:selection="{ index, text }">
          <v-chip
            v-if="index < 13"
            color="deep-purple accent-4"
            dark
            label
            small
          >
            {{ text }}
          </v-chip>

          <span
            v-else-if="index === 13"
            class="overline grey--text text--darken-3 mx-2"
          >
            +{{ files.length - 12 }} File(s)
          </span>
        </template>
      </v-file-input>
      <server-formset
        title="Uploaded files"
        :data-url="urlPath"
        class="mt-7"
        ref="uploaded_files"
        ></server-formset>
    </v-card>
  </div>
</template>

<script>
import ServerFormset from '@/modules/crud/components/ServerFormset'
import UploadedAlbum from './UploadedAlbum'
export default {
  components: {
    ServerFormset,
    UploadedAlbum
  },
  data () {
    return {
      urlPath: 'upload/uploadedfile',
      mode: null,
      files: [],
      text: ''
    }
  },
  methods: {
    async upload_files () {
      if (this.files.length) {
        const action = 'file-upload'
        const config = {
          prefix: 'upload'
        }
        // make a copy of the files array
        const files = this.files.slice()

        for (let i = 0; i < files.length; i++) {
          const file = files[i]
          config.files = ['files', [file]]
          await this.$api.upload(action, config)
          this.files.splice(0, 1)
          this.$refs.uploaded_files.refresh()
        }
      }
    }
  }
}
</script>
