<template>
  <div v-if="show">
    <component
      v-model="fieldValue"
      :is="fieldComponent"
      :name="name"
      :options="options"
      :errors="errors"
      :reload="reload"
      :rules="fieldRules(options)"
      :single-line="singleLine"
      :outlined="outlined"
      :hide-labels="hideLabels"
      :class="{'field--limited-width': options.limited_width}"
      @input="changeValue()"
    />
  </div>
</template>
<script>

export default {
  name: 'FormField',
  props: [
    'name',
    'value',
    'options',
    'errors',
    'reload',
    'singleLine',
    'outlined',
    'hideLabels'
  ],
  data () {
    return {
      fieldValue: null,
      isEmitLocked: false
    }
  },
  computed: {
    show () {
      return true
    },
    fieldType () {
      return this.options.field_type
    },
    fieldComponent () {
      let imported
      if (this.fieldType) {
        try {
          imported = () => import(`./fields/${this.options.field_type}.vue`)
        } catch (e) {
          if (e.code === 'MODULE_NOT_FOUND') {
            return undefined
          } else {
            throw e
          }
        }
        return imported
      } else {
        return undefined
      }
      // return this.options.field_type ? () => import(`./fields/${this.options.field_type}.vue`) : undefined
    },
    rules () {
      const self = this
      return {
        required: (v) => !(v === '') || self.$t('global.details.rules.required')
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (val) {
        this.fieldValue = val
      }
    }
  },
  methods: {
    fieldRules (options) {
      const rules = []
      const required = options.required !== undefined ? options.required : false
      if (required) {
        rules.push(this.rules.required)
      }
      return rules
    },
    changeValue () {
      // pass the change up
      this.$emit('valueChanged', this.name, this.fieldValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.field {
  &--limited-width {
    max-width: 600px;
  }
}
</style>
