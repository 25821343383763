<template>
<v-row justify="center" class="mt-4">
  <v-card flat width="95%">

    <v-app-bar dark color="primary">
      <v-icon left>mdi-album</v-icon>
      <v-toolbar-title>Album</v-toolbar-title>
      <v-spacer></v-spacer>
      <template v-if="upAlbum.album_uid">
        <icon-button
          icon='mdi-delete'
          @clicked="onDelete"
          :tooltip="$t('global.form.buttons.delete')"
          color="white"
        ></icon-button>
      </template>
      <upload-album-search
        @confirm="set_upalbum"
        v-else
      />

    </v-app-bar>
    <v-card-text v-if="upAlbum.album_uid">
      <v-row justify="center">
        <div class="d-flex flex-no-wrap justify-space-between">
          <div class="my-3">
            <h3 class="title">
              {{ upAlbum.artist_name }}
            </h3>
            <h3 class="headline">
              {{ upAlbum.album_title }}
            </h3>
          </div>

          <v-avatar class="ma-3" size="125" tile>
            <v-img :src="upAlbum.image"></v-img>
          </v-avatar>
        </div>
      </v-row>
      <v-row justify="center">
        <div>
          <server-table
            title="Existing tracks"
            :data-url="tracksUrl"
            :actions="null"
          >
          </server-table>
        </div>
      </v-row>
    </v-card-text>
  </v-card>
  <confirm-dialog
    :dialog="confirmDelete"
    :dialogTitle="$t('global.alerts.confirmDeleteTitle')"
    :dialogText="confirmDeleteText"
    @onConfirm="onConfirmDelete"
    @onCancel="onCancelDelete"
    ></confirm-dialog>
</v-row>
</template>

<script>
import IconButton from '@/modules/app/components/IconButton'
import ConfirmDialog from '@/modules/crud/components/ConfirmDialog.vue'
import ServerTable from '@/modules/crud/components/ServerTable'
import UploadAlbumSearch from './UploadAlbumSearch'
import { mapState, mapMutations, mapActions } from 'vuex'
export default {
  components: {
    IconButton,
    UploadAlbumSearch,
    ConfirmDialog,
    ServerTable
  },
  data () {
    return {
      confirmDeleteText: '',
      confirmDelete: false,
      tracksBaseUrl: 'upload/albumtracks'
    }
  },
  computed: {
    ...mapState('music', ['upAlbum']),
    tracksUrl () {
      return `${this.tracksBaseUrl}/${this.upAlbum.album}`
    }
  },
  mounted () {
    this.getUpAlbumInfo().then((data) => {
      this.setUpAlbum(data)
    })
  },
  methods: {
    ...mapMutations('music', ['setUpAlbumUID', 'setUpAlbum']),
    ...mapActions('music', ['getUpAlbumInfo', 'removeUpAlbum']),
    set_upalbum (uid) {
      this.getUpAlbumInfo(uid)
    },
    onDelete (item) {
      this.confirmDeleteText = this.$t('global.alerts.confirmDeleteText', {
        obj: 'uploaded album'
      })
      this.confirmDelete = true
    },
    onConfirmDelete () {
      const uid = this.upAlbum.album_uid
      this.removeUpAlbum(uid)
      this.confirmDelete = false
    },
    onCancelDelete () {
      this.confirmDelete = false
    }
  }
}
</script>

<style>

</style>
