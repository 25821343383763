<template>
  <div>
    <server-table
      :data-url="dataUrl"
      :detail-name="detailName"
      :detail-url="detailUrl"
      :actions="actions"
    ></server-table>
  </div>
</template>

<script>
import ServerTable from '@/modules/crud/components/ServerTable'
export default {
  components: {
    ServerTable
  },
  data () {
    return {
      dataUrl: 'music/artist',
      detailName: 'artist-view',
      detailUrl: '/music/artists/%s',
      actions: ['view', 'delete']
    }
  }
}
</script>
