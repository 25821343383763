import Home from './Home.vue'
import musicRoutes from './music/routes'
import djqueRoutes from './djque/routes'
import statsRoutes from './stats/routes'

// import Administration from './routes/administration/Index.vue'
// import administrationRoutes from './routes/administration/router'

const appRoutes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      menu_title: 'Home',
      icon: 'mdi-home'
    }
  },
  musicRoutes,
  djqueRoutes,
  statsRoutes
  // programsRoutes,
  // playersRoutes
  /*
  {
    path: 'administration',
    name: 'administration',
    component: Administration,
    children: administrationRoutes,
    meta: { guard: 'ADMIN' },
  } */
]

export default appRoutes
