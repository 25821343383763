<template>
  <v-dialog
    v-model="show"
    :max-width="width || defaultWidth"
    persistent
  >
    <v-card>
      <v-card-title>
        <slot name="title">{{ title }}</slot>
      </v-card-title>
      <v-card-text>
        <slot>
          <form-body
            v-model="isFormValid"
            :fields="fields"
            :values="values"
            :errors="errors"
            @valueChanged="valueChanged"
            ></form-body>
        </slot>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <slot name="actions">
          <v-btn color="black" text @click.native="close">{{ $t('global.form.buttons.close') }}</v-btn>
          <v-btn
            :disabled="!isFormValid"
            color="green"
            text
            @click="save"
          >{{ $t('global.form.buttons.save') }}</v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import FormBody from './FormBody'
import FormMixin from './mixins/form-instance.js'
export default {
  components: {
    FormBody
  },
  mixins: [
    FormMixin
  ],
  props: {
    name: String, // modal key name
    dataUrl: String,
    width: Number
  },
  data () {
    return {
      defaultWidth: 600
    }
  },
  computed: {
    ...mapState('crud', [
      'modalOpen', 'modalKey'
    ]),
    show () {
      return this.modalOpen[this.name]
    },
    itemKey () {
      return this.modalKey[this.name]
    }
  },
  watch: {
    show (value) {
      if (value) {
        this.getOptions(true)
      }
    }
  },
  methods: {
    ...mapMutations('crud', ['closeModal']),
    close () {
      this.closeModal(this.name)
    }
  }
}
</script>
