/**
 * Install api plugin.
 */

import axios from 'axios'
import { trimStart, trimEnd } from 'lodash/string'
import config from '@/config/api.js'

function getUrl (baseUrl, prefix, pathname = '') {
  const result = new URL(baseUrl || config.url)
  const urlPrefix = prefix
  if (urlPrefix) {
    result.pathname = trimEnd(result.pathname, '/') + '/' + trimStart(urlPrefix, '/')
  }
  if (pathname) {
    result.pathname = trimEnd(result.pathname, '/') + '/' + trimStart(pathname, '/')
  }
  return result.href
}

const defaultOptions = {
  // request interceptor handler
  reqHandleFunc: config => config, // auth
  reqErrorFunc: error => Promise.reject(error),
  // response interceptor handler
  resHandleFunc: response => response,
  resErrorFunc: error => Promise.reject(error)
}

const ApiPlugin = {
  install (Vue, options) {
    const initOptions = {
      ...defaultOptions,
      ...options
    }
    const instance = axios.create(initOptions)

    // Add a request interceptor
    instance.interceptors.request.use(
      config => initOptions.reqHandleFunc(config),
      error => initOptions.reqErrorFunc(error)
    )
    // Add a response interceptor
    instance.interceptors.response.use(
      response => initOptions.resHandleFunc(response),
      error => initOptions.resErrorFunc(error)
    )

    const requested = function (method) {
      return function (action, config = {}) {
        const url = getUrl(config.baseUrl, config.prefix, action)
        const axiosOpt = {
          ...config.options,
          ...{
            method,
            url,
            params: method === 'get' && config.data !== undefined ? config.data : undefined,
            data: ['post', 'put'].includes(method) ? config.data : undefined
          }
        }
        return instance(axiosOpt)
      }
    }

    Vue.api = {}

    const methods = ['get', 'put', 'post', 'delete']
    methods.forEach((method) => { Vue.api[method] = requested(method) })

    Vue.api.upload = function (action, config) {
      const [field, files] = config.files
      const formData = new FormData()

      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        formData.append(`${field}[${i}]`, file)
      }
      config.data = formData
      return Vue.api.post(action, config)
    }

    Vue.prototype.$api = Vue.api
  }
}

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(ApiPlugin)
}

export default ApiPlugin
