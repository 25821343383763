<template>
  <span>
    <span v-if="options.type === 'yesicon'">
      <v-icon v-if="value" color="green">mdi-check-circle</v-icon>
    </span>
    <span v-else>{{ value }}</span>
  </span>
</template>

<script>
export default {
  props: [
    'options',
    'value'
  ]
}
</script>

<style>

</style>
