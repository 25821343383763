<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" lg="5" class="pa-3">
        <v-card light>
          <v-card-title>
            <slot name="title"></slot>
          </v-card-title>
          <v-card-text>
            <slot>
            </slot>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <slot name="actions">
            </slot>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" lg="7" class="pa-3">
        <slot name="right_side"></slot>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import Controls from './Controls'
export default {
  name: 'ViewLayout',
  components: {
    // Controls
  },
  mixins: [
  ],
  props: {
    show: Boolean,
    title: String,
    showPrevNext: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  }
}
</script>

<style scoped>
.no-border-radius {
  border-radius: 0!important;
}
</style>
