const getters = {
  isLogged: state => state.logged,
  getToken: state => state.token,
  userName: state => state.user.name || state.user.email,
  userInfo: state => state.user,
  userPermissions: state => state.permissions || [],
  checkPermission: (state, getters) => (roleCode) => {
    const result = !!(roleCode === undefined || getters.userPermissions.filter(el => el === roleCode).length > 0)
    return result
  }
}

export default getters
