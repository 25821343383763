import Vue from 'vue'

const mutations = {
  login (state, { token, user, permissions }) {
    state.logged = true
    /*
    const user = JSON.stringify(data.user)
    const permissions = JSON.stringify(data.permissions)
    localStorage.setItem('token', token)
    localStorage.setItem('user', user)
    localStorage.setItem('permissions', permissions)
    */
    state.token = token
    Vue.set(state, 'user', user)
    Vue.set(state, 'permissions', permissions)
  },
  logout (state) {
    state.logged = false
    state.token = null
    Vue.set(state, 'user', null)
    Vue.set(state, 'permissions', null)
    // localStorage.removeItem('token')
    // localStorage.removeItem('user')
    // localStorage.removeItem('permissions')
  },
  setUser (state, user) {
    Vue.set(state, 'user', user)
  },
  setPermissions (state, permissions) {
    Vue.set(state, 'permissions', permissions)
  }
}

export default mutations
