const getters = {
  getModalOpen: (state) => (name) => {
    return state.modalOpen[name] || false
  },
  getModalKey: (state) => (name) => {
    return state.modalKey[name]
  }
}

export default getters
