import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from '@/locales/en/index'
import cs from '@/locales/cs/index'
// import messages from '@/locales/en'
// import axios from 'axios'
// import router from '@/router'

Vue.use(VueI18n)

// function loadLocaleMessages () {
//   const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
//   const messages = {}
//   locales.keys().forEach(key => {
//     const matched = key.match(/([A-Za-z0-9-_]+)\./i)
//     if (matched && matched.length > 1) {
//       const locale = matched[1]
//       messages[locale] = locales(key)
//     }
//   })
//   return messages
// }

// export default new VueI18n({
//   locale: process.env.VUE_APP_I18N_LOCALE || 'en',
//   fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
//   messages: loadLocaleMessages()
// })

const messages = {
  cs: cs,
  en: en
}

export default new VueI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en',
  messages: messages
})

/*
const loadedLanguages = ['en'] // our default language that is preloaded

function setI18nLanguage (lang) {
  i18n.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync (lang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language hasn't been loaded yet
  return import(
    /* webpackChunkName: "lang-[request]" */
/* `@/locales/${lang}`).then(
    messages => {
      i18n.setLocaleMessage(lang, messages.default)
      loadedLanguages.push(lang)
      return setI18nLanguage(lang)
    }
  )
} */

// router.beforeEach((to, from, next) => {
//   const lang = to.params.lang
//   console.log(lang)
//   if (lang) {
//     loadLanguageAsync(lang).then(() => next())
//   }
// })
