export default {
  alerts: {
    updated: 'Updated',
    updateError: 'Error! Update unsuccessful',
    stored: 'Dodano',
    storeError: 'Error! Store unsuccessful'
  },
  buttons: {
    create: 'Create',
    modify: 'Modify',
    modifySelected: 'Modify selected records',
    refresh: 'Refresh',
    previous: 'Previous',
    next: 'Next',
    close: 'Close',
    delete: 'Delete',
    save: 'Save',
    view: 'View'
  },
  files: {
    upload: 'Upload file',
    show: 'Show',
    download: 'Download'
  }
}
