<template>
  <v-card
    class="mx-auto"
    color="grey lighten-4"
  >
    <v-sheet color="transparent">
      <v-sparkline
        :smooth="0"
        :gradient="['#1faaea']"
        :line-width="1"
        :labels="labels"
        :value="values"
        :auto-draw="draw"
        :show-labels="show_labels"
        label-size="3"
        type="trend"
        stroke-linecap="round"
      ></v-sparkline>
    </v-sheet>
  </v-card>
</template>

<script>
export default {
  props: {
    items: {
      type: [Array, Function],
      default: () => []
    },
    label_key: {
      type: String,
      default: 'label'
    },
    value_key: {
      type: String,
      default: 'value'
    }
  },
  data: () => ({
    draw: false,
    label_show_limit: 25
  }),
  computed: {
    labels () {
      if (this.show_labels && !!this.items && this.items.length) {
        return this.items.map(obj => { return obj[this.label_key] })
      } else {
        return []
      }
    },
    values () {
      if (!!this.items && this.items.length) {
        return this.items.map(obj => { return obj[this.value_key] })
      }
      return []
    },
    show_labels () {
      if (!!this.items && this.items.length) {
        return this.items.length > 0 && this.items.length < this.label_show_limit
      }
      return false
    },
    items_length () {
      return this.items ? this.items.length : 0
    }
  },
  watch: {
    // display sparkline if enough data
    items_length: function (newValue, oldValue) {
      this.draw = newValue > 1
    }
  }
}
</script>
