<template>
  <v-app-bar
    app
    color="blue darken-3"
    dark
  >
    <v-app-bar-nav-icon @click.stop="toggleDrawer()" />
    <!-- v-text-field
      flat
      solo-inverted
      hide-details
      prepend-inner-icon="mdi-magnify"
      label="Search"
      class="hidden-sm-and-down"
    / -->
    <v-spacer />
    <icon-button icon='mdi-undo-variant' @clicked="goBack" :tooltip="$t('global.app.buttons.goback')" color="white"></icon-button>
    <!-- v-btn icon>
      <v-icon>mdi-bell</v-icon>
    </v-btn -->
    <v-btn text>
      <v-icon>mdi-account-details</v-icon>
      {{ userName }}
    </v-btn>
    <!-- v-btn icon>
      <v-icon>mdi-apps</v-icon>
    </v-btn -->
  </v-app-bar>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import IconButton from './IconButton'
export default {
  components: {
    IconButton
  },
  computed: {
    ...mapGetters('auth', ['userName'])
  },
  data: () => ({}),
  methods: {
    ...mapMutations('app', ['toggleDrawer']),
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>
