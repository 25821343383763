import alerts from './alerts'
import app from './app'
import login from './login'
import profile from './profile'
import datatable from './datatable'
import details from './details'
import form from './form'
import itemElements from './item-elements'

export default {
  global: {
    alerts,
    app,
    login,
    profile,
    datatable,
    details,
    form,
    itemElements
  }
}
