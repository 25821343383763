import Vue from 'vue'

function makePath (subroute, route) {
  return subroute.path.startsWith('/') ? subroute.path : route.path + (route.path.endsWith('/') ? '' : '/') + subroute.path
}

function createMenu (routes) {
  const items = []
  routes.forEach(route => {
    if (route.meta && route.meta.menu_title) {
      const item = {
        text: route.meta.menu_title,
        name: route.name,
        path: route.path,
        icon: route.meta ? route.meta.icon : null
      }
      if (route.children) {
        item.icon = 'mdi-chevron-up'
        item['icon-alt'] = 'mdi-chevron-down'
        item.model = false
        item.children = []
        route.children.forEach(subroute => {
          if (subroute.meta && subroute.meta.menu_title) {
            const child = {
              text: subroute.meta.menu_title,
              name: subroute.name,
              path: makePath(subroute, route),
              icon: subroute.meta.icon
            }
            item.children.push(child)
          }
        })
      }
      items.push(item)
    }
  })
  return items
}

/**
 * number_format(number, decimals, decPoint, thousandsSep) in JavaScript, known from PHP.
 * It formats a number to a string with grouped thousands, with custom seperator and custom decimal point
 * @param {number} number - number to format
 * @param {number} [decimals=0] - (optional) count of decimals to show
 * @param {string} [decPoint=.] - (optional) decimal point
 * @param {string} [thousandsSep=,] - (optional) thousands seperator
 * @author Felix Leupold <felix@xiel.de>
 * https://gist.github.com/xiel/5688446§
 */
function formatNumber (number, decimals, decPoint, thousandsSep) {
  if (number == null) {
    return ''
  }
  decimals = Math.abs(decimals) || 0
  number = parseFloat(number)

  if (!decPoint || !thousandsSep) {
    decPoint = ','
    thousandsSep = '\u2009'
  }

  var roundedNumber = Math.round(Math.abs(number) * ('1e' + decimals)) + ''
  var numbersString = decimals ? (roundedNumber.slice(0, decimals * -1) || 0) : roundedNumber
  var decimalsString = decimals ? roundedNumber.slice(decimals * -1) : ''
  var formattedNumber = ''

  while (numbersString.length > 3) {
    formattedNumber += thousandsSep + numbersString.slice(-3)
    numbersString = numbersString.slice(0, -3)
  }

  if (decimals && decimalsString.length === 1) {
    while (decimalsString.length < decimals) {
      decimalsString = decimalsString + decimalsString
    }
  }

  return (number < 0 ? '-' : '') + numbersString + formattedNumber + (decimalsString ? (decPoint + decimalsString) : '')
}

function humanFileSize (bytes, si) {
  const thresh = si ? 1000 : 1024
  if (bytes == null) {
    return ''
  }
  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`
  }
  const units = si
    ? [
      'kB',
      'MB',
      'GB',
      'TB',
      'PB',
      'EB',
      'ZB',
      'YB'
    ]
    // : ['KiB','MiB','GiB','TiB','PiB','EiB','ZiB','YiB'];
    : [
      'KB',
      'MB',
      'GB',
      'TB',
      'PB',
      'EB',
      'ZB',
      'YB'
    ]
  let u = -1
  do {
    bytes /= thresh
    ++u
  } while (Math.abs(bytes) >= thresh && u < units.length - 1)
  const valueFmt = formatNumber(bytes, 1)
  return `${valueFmt} ${units[u]}`
  // return `${bytes.toFixed(1)} ${units[u]}`
}

function objectUpdate (target, source, vue = undefined) {
  // update only original attributes in target
  for (var p in target) {
    if (source[p] !== undefined) {
      if (vue !== undefined) {
        vue.$set(target, p, source[p])
      } else {
        Vue.set(target, p, source[p])
      }
    }
  }
}

function compareObjects (a, b) {
  for (const key in a) {
    if (b[key] === undefined || b[key] !== a[key]) {
      // console.log(key, a[key], '!=', b[key])
      return false
    }
  }
  return true
}

export {
  createMenu,
  humanFileSize,
  formatNumber,
  objectUpdate,
  compareObjects
}
