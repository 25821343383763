import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
// app
import app from '@/modules/app/store/'
// auth
import auth from '@/modules/auth/store/'
// crud
import crud from '@/modules/crud/store'
// music
import music from '@/views/app/music/store'

// main store
import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

Vue.use(Vuex)

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    app,
    auth,
    crud,
    music
  },
  plugins: [createPersistedState({
    paths: ['auth.logged', 'auth.token', 'auth.user', 'auth.permissions']
  })]
})
