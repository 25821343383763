export default {
  base_url: process.env.BASE_URL === 'http://0.0.0.0:8080/' ? 'http://127.0.0.1:8000' : '',
  prefix: 'auth',
  paths: {
    login: 'login',
    logout: 'logout',
    refreshToken: 'refresh/',
    getUser: 'users/me/',
    editUser: 'users/me/',
    changePassword: 'users/set_password/'
  },
  authorizationHTTPHeader: 'Authorization',
  authorizationHTTPKeyword: 'Bearer',
  loginWithEmail: false,
  loginField: 'username',
  loginEditable: true,
  loginMinLength: 4,
  loginMaxLength: 100,
  // loginRegex: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
  passwordField: 'password',
  passwordEditable: true,
  passwordMinLength: 1,
  passwordMaxLength: 100,
  customFields: [
    {
      name: 'name',
      regex: /^.{0,100}$/,
      editable: true,
      minLength: 0,
      maxLength: 100,
      required: true
    }
  ]
}
