import Vue from 'vue'
import VueRouter from 'vue-router'
import App from '../views/app/Index.vue'
import appRoutes from '../views/app/routes'
import Login from '../views/Login.vue'
import store from '../store/'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: App,
    children: appRoutes,
    beforeEnter: (to, from, next) => {
      var auth = store.getters['auth/getToken']
      if (!auth) {
        store.commit('auth/logout')
        next('/login')
      } else {
        var user = store.state['auth/user']
        if (!user) {
          store.dispatch('auth/getUser').then(response => {
            next()
          })
        } else {
          next()
        }
      }
    }
  },
  // { path: '/about', name: 'About', component: About },
  // { path: '/dashboard', component: Dashboard, name: 'Dashboard', beforeEnter: requireAuth },
  // { path: '/about', component: About, name: 'About', beforeEnter: requireAuth },
  // { path: '/404', component: ErrorPage, name: 'ErrorPage' },
  { path: '/login', component: Login, name: 'Login' }
]
const router = new VueRouter({
  // mode: 'history',
  // scrollBehavior: () => ({ y: 0 }),
  routes
})

router.beforeEach(function (to, from, next) {
  let middleware
  to.matched.some(m => {
    middleware = m.meta.guard
  })
  if (typeof middleware === 'undefined') {
    next()
  } else {
    if (store.getters['auth/checkPermission'](middleware)) {
      window.scrollTo(0, 0)
      next()
    } else if (store.getters['auth/isLogged']) {
      next('/home')
    } else {
      next('/login')
    }
  }
})

export default router
