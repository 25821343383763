<template>
  <v-tooltip top v-if="code">
    <template v-slot:activator="{ on }">
      <img
        class="gb-flag"
        :class="[`gb-flag--${size}`, { 'gb-flag--clickable': $listeners.click }]"
        :src="path"
        :style="{
          height: height,
          width: width
        }"
        v-on="on"
      />
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    code: {
      type: String,
      required: false
    },
    name: {
      type: String,
      required: false
    },
    height: {
      type: String,
      default: null
    },
    iconPath: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: 'default',
      validator (x) {
        return ['nano', 'micro', 'mini', 'small', 'default', 'medium', 'large', 'huge'].includes(x)
      }
    },
    width: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },
  computed: {
    path () {
      const code = (this.code || '').toLowerCase()
      let path = this.iconPath || this.publicPath + 'flags'

      // Remove last character if slash
      path = path.replace(/\/$/, '')

      // Use fallback flag
      /* if (!flags.includes(code)) {
        code = 'unknown'
      } */

      return `${path}/${code}.svg`
    },
    tooltip () {
      return this.name || (this.code ? this.code.toUpperCase() : '')
    }
  },

  methods: {
    onClick (event) {
      this.$emit('click', event)
    }
  }
}
</script>

<!-- *************************************************************************
     STYLE
     ************************************************************************* -->

<style lang="scss">
// VARIABLES
$c: ".gb-flag";
$sizes: "nano", "micro", "mini", "small", "default", "medium", "large", "huge";

#{$c} {
  display: inline-block;
  background-size: cover;
  background-repeat: no-repeat;
  user-select: none;

  // --> SIZES <--

  @each $size in $sizes {
    $i: index($sizes, $size) - 1;

    &--#{$size} {
      height: 10px + ($i * 5);
    }
  }

  // --> BOOLEANS <--

  &--clickable {
    cursor: pointer;
  }
}
</style>
