import Vue from 'vue'
import auth from '@/config/auth'
import router from '@/router'
import store from '@/store'
import ApiPlugin from './api_plugin'

Vue.use(ApiPlugin, {
  reqHandleFunc: config => {
    const token = store.getters['auth/getToken']
    if (token) {
      config.headers[auth.authorizationHTTPHeader] = auth.authorizationHTTPKeyword + ' ' + token
    }
    // config.headers['Content-Type'] = 'application/json'
    store.commit('setLoading', true, { root: true })
    return config
  },
  reqErrorFunc: error => {
    store.commit('setLoading', false, { root: true })
    store.dispatch('openAlertBox', [
      'alertError',
      error
    ], { root: true })
    return Promise.reject(error)
  },
  resHandleFunc: response => {
    store.commit('setLoading', false, { root: true })
    return response
  },
  resErrorFunc: error => {
    // console.log('Response error:', error)
    store.commit('setLoading', false, { root: true })
    const response = error.response
    if (response && (response.status === 401 || response.status === 403)) {
      // console.log('Forced logging out')
      store.commit('auth/logout')
      router.push({ path: '/login' })
    } else if (response && (response.status !== 422)) {
      const msg = error.message || error.statusText || error
      store.dispatch('openAlertBox', [
        'alertError',
        msg
      ], { root: true })
    }
    return Promise.reject(error)
  }
})

export default ApiPlugin
