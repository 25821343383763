<!-- Displays only readonly fields of a form -->
<template>
  <div>
    <v-row v-for="(field, name) in visible_fields" :key="name">
    <!-- v-col class="sm12" -->
      <v-text-field
        v-model="values[name]"
        type="text"
        :label="field.label"
        dense
        readonly
        solo
        flat
        hide-details
      >
        <template v-slot:prepend-inner>
          <strong>{{ field.label }}:</strong>
        </template>
      </v-text-field>
    <!-- /v-col -->
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'FormView',
  props: {
    fields: Object,
    values: Object,
    exclude: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {}
  },
  computed: {
    visible_fields () {
      return Object.assign(
        {},
        Object.fromEntries(
          Object.entries(this.fields).filter((tuple) => !this.exclude.includes(tuple[0]) && !tuple[1].hidden && tuple[1].read_only)
        )
      )
    }
  },
  methods: {
  }
}
</script>

<style>

</style>
