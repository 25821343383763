import Artists from './Artists'
import ArtistView from './ArtistView'
import Albums from './Albums'
import AlbumView from './AlbumView'
import Tracks from './Tracks'
import Upload from './Upload'
import Music from './Index.vue'

export default {
  path: '/music',
  name: 'music',
  component: Music,
  children: [
    {
      path: 'upload',
      name: 'upload',
      component: Upload,
      meta: {
        menu_title: 'Upload',
        icon: 'mdi-folder-upload-outline'
      }
    },
    {
      path: 'artists',
      name: 'artists',
      component: Artists,
      meta: {
        menu_title: 'Artists',
        icon: 'mdi-account-music'
      }
    },
    {
      path: 'artists/:id',
      name: 'artist',
      component: ArtistView
    },
    {
      path: 'albums',
      name: 'albums',
      component: Albums,
      meta: {
        menu_title: 'Albums',
        icon: 'mdi-album'
      }
    },
    {
      path: 'albums/:id',
      name: 'album',
      component: AlbumView
    },
    {
      path: 'tracks',
      name: 'tracks',
      component: Tracks,
      meta: {
        menu_title: 'Tracks',
        icon: 'mdi-music-circle-outline'
      }
    }
  ],
  meta: {
    menu_title: 'Music'
  }
}
