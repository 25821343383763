import Vue from 'vue'
import config from '@/config/api'

const actions = {
  uploadSearch ({ commit }, { action, search }) {
    return new Promise((resolve, reject) => {
      Vue.api.get(action, {
        prefix: config.prefix.upload,
        data: search
      }).then((response) => {
        resolve(response.data)
      })
    })
  },
  getUpAlbumInfo ({ commit }, uid) {
    return new Promise((resolve, reject) => {
      let action = 'uploaded-album'
      action = uid ? `${action}/${uid}` : action
      Vue.api.get(action, {
        prefix: config.prefix.upload
      }).then((response) => {
        commit('setUpAlbum', response.data)
      })
    })
  },
  removeUpAlbum ({ commit }, uid) {
    return new Promise((resolve, reject) => {
      let action = 'uploaded-album'
      action = `${action}/${uid}`
      Vue.api.delete(action, {
        prefix: config.prefix.upload
      }).then((response) => {
        commit('setUpAlbum', {})
      })
    })
  }
}

export default actions
