export default {
  url:
    process.env.NODE_ENV === 'production'
      ? 'https://u4.gaiagroup.cz'
      : 'http://localhost:8000',
  prefix: {
    tables: 'tables',
    forms: 'forms',
    upload: 'upload'
  }
}
