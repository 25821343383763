<template>
  <v-form v-model="formValid" @input="input" v-if="fields">
    <slot name="over-fields" />
    <v-row v-for="(field, name) in fields" :key="name">
      <template v-if="!field.hidden">
        <v-col class="sm12">
          <form-field
            :name="name"
            :value="values[name]"
            :options="field"
            :errors="errors[name]"
            @valueChanged="valueChanged"
          ></form-field>
        </v-col>
      </template>
    </v-row>
    <slot name="under-fields" />
  </v-form>
</template>

<script>
import FormField from './FormField'
export default {
  name: 'FormBody',
  components: { FormField },
  props: [
    'value',
    'fields',
    'values',
    'errors'
  ],
  data () {
    return {
      formValid: this.value
    }
  },
  methods: {
    input (value) {
      this.$emit('input', value)
    },
    valueChanged () {
      this.$emit('valueChanged', ...arguments)
    }
  }
}
</script>
