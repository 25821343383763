<template>
<v-dialog v-model="show" persistent max-width="320">
    <v-card>
      <v-card-title>{{ title || this.$t('global.alerts.confirmDeleteTitle')}}</v-card-title>
      <v-card-text>{{ confirmationText }}</v-card-text>
      <v-card-actions class="justify-end">
        <v-btn class="orange--text darken-1" text @click.native="$emit('onCancel')">Cancel</v-btn>
        <v-btn class="green--text darken-1" text @click.native="$emit('onConfirm', obj)">Confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    show: Boolean,
    title: {
      type: String,
      default: null
    },
    obj: {
      type: Object,
      required: false
    },
    objIdent: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      mini: false,
      isRootComponent: true,
      // clipped: false,
      drawer: true,
      fixed: false
    }
  },
  computed: {
    confirmationText () {
      return this.$t('global.alerts.confirmDeleteText', {
        obj: this.objIdent || 'this item'
      })
    }
  }
}
</script>
