<template>
<v-container fill-height justify-center align-center >
  <!-- <v-layout row > -->
    <v-flex xs12 sm5 md4 lg3>
      <v-card class="mt-0 pt-0">
          <v-card-title class="blue darken-1">
            <h4 style="color:white">Universe</h4>
          </v-card-title>
          <v-card-text>
              <form @submit.prevent="loginAttempt">
                <v-layout row wrap>
                  <v-flex xs12 md4 >
                    <v-subheader>User ID</v-subheader>
                  </v-flex>
                  <v-flex  xs12 md8>
                    <v-text-field class="input-group--focused" :name="loginField" v-model="userid" :label="loginField" value="Input text"></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex  xs12 md4>
                    <v-subheader>Password</v-subheader>
                  </v-flex>
                  <v-flex  xs12 md8>
                    <v-text-field class="input-group--focused" name="password" type="password" v-model="pass" :label="passwordField" value="Input text"></v-text-field>
                  </v-flex>
                </v-layout>
                <v-btn type="submit">login</v-btn>
                <v-snackbar v-if="error" :timeout="timeout" :top="true" :multi-line="true" v-model="error">
                  {{ text }}
                  <v-btn class="pink--text" text @click.native="error = false">Close</v-btn>
                </v-snackbar>
              </form>
        </v-card-text>
      </v-card>
    </v-flex>
  <!-- </v-layout> -->
  </v-container>
</template>
<script>
import {
  mapState,
  mapActions
} from 'vuex'
import auth from '@/config/auth'
export default {
  data () {
    return {
      userid: '',
      pass: '',
      error: false,
      text: '',
      timeout: 5000
    }
  },
  computed: {
    ...mapState('auth', [
      'logged'
    ]),
    loginField () {
      return auth.loginField || 'login'
    },
    passwordField () {
      return auth.passwordField || 'password'
    },
    credentials () {
      const credentials = {}
      credentials[this.loginField] = this.userid
      credentials[this.passwordField] = this.pass
      return credentials
    }
  },
  methods: {
    ...mapActions('auth', ['login']),
    loginAttempt () {
      this.login(this.credentials).then(() => {
        if (this.logged) {
          this.$router.push(this.$route.query.redirect || '/')
        } else {
          this.error = true
          this.text = 'Bad login information'
        }
      }).catch(error => {
        this.error = true
        this.text = error
      })
    }
  }
}
</script>
<style lang="sass">
  @import '../styles/main'
</style>
