import { mapActions } from 'vuex'
import config from '@/config/api'
export default {
  data () {
    return {
      isFormValid: false,
      title: '',
      fields: {},
      values: {},
      errors: {}
      /* external expected args
      name : '',
      dataUrl: '',
      itemKey: ''
      */
    }
  },
  methods: {
    ...mapActions(['openAlertBox']),
    refresh () {
      this.getOptions()
    },
    valueChanged (name, value) {
      this.$set(this.values, name, value)
    },
    getOptions (includeConfig = false) {
      const urlPath = `${this.dataUrl}/${this.itemKey}`
      const params = includeConfig ? { includeConfig: includeConfig } : undefined
      this.$api.get(urlPath, { prefix: config.prefix.forms, data: params })
        .then((response) => {
          const data = response.data
          if (data.options !== undefined) {
            this.options = data.options
            this.fields = this.options.fields
            this.title = this.options.title
            this.values = data.form_data
          } else {
            this.values = data
          }
        })
    },
    save () {
      const urlPath = `${this.dataUrl}/${this.itemKey}`

      this.$api.put(urlPath, {
        prefix: config.prefix.forms,
        data: this.values
      }).then((response) => {
        const data = response.data
        if (data.status === 'success') {
          this.openAlertBox(['alertSuccess', this.$t('global.alerts.updated')])
          this.close()
          // TODO: emit event - this.refresh()
        }
      }, (error) => {
        // handle validation errors
        if (error.status === 422 && 'errors' in error.body) {
          this.errors = error.body.errors
        } else {
          const errorMsg = this.$t('global.alerts.updateError') + ' ' + error.bodyText
          this.openAlertBox(['alertError', errorMsg])
        }
      })
    }
  }
}
