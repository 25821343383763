<template>
  <!-- Tree -->
  <v-treeview
    :items="treeItems"
    class="tree"
    color="default"
    hoverable
    activatable
    dense
    transition
    return-object
    @update:active="update_active"
  >
  </v-treeview>
</template>

<script>
export default {
  name: 'CrudTree',
  components: {
  },
  props: {
    prefix: {
      type: String,
      default: null
    },
    dataUrl: {
      type: String,
      default: null
    },
    refresh: {
      type: Boolean,
      default: false
    },
    pageTitle: String,
    fieldsInfo: Array,
    customButtons: {
      type: Array,
      default: () => []
    },
    meta: {
      type: Array,
      default: () => []
    },
    primaryKey: {
      type: String,
      default: 'id'
    },
    activeColumnName: {
      type: String,
      default: 'active'
    }
  },
  data () {
    return {
      items: [],
      active_items: []
    }
  },
  computed: {
    tableFields () {
      if (this.fieldsInfo) {
        return this.fieldsInfo.filter(field => field.table !== false && field.type !== 'divider')
      }
      return []
    },
    treeItems () {
      const itemTreeList = (items) => {
        const computedItems = []
        for (const item of items) {
          const children = item.children
          const obj = this.getItemsList(item, this.tableFields, this.meta, this.primaryKey, this.customButtons, this.activeColumnName)
          if (children) {
            obj.children = itemTreeList(children)
          }
          computedItems.push(obj)
        }
        return computedItems
      }

      const computedItems = itemTreeList(this.items)
      return computedItems
    }
  },
  methods: {
    update_active (val) {
      this.$emit('update_active', val)
    },
    getItemsList (item) {
      return item
    },
    getItems () {
      return new Promise((resolve, reject) => {
        const data = this.params
        this.$api.post(this.dataUrl, {
          prefix: this.prefix,
          data: data
        })
          .then((response) => {
            const data = response.data
            this.items = data.data
            resolve(data)
          }, (error) => {
            reject(error)
          })
      })
    }
  },
  mounted () {
    this.getItems()
  },
  watch: {
    refresh (val) {
      if (val) {
        this.getItems()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.tree {
  max-width: 100%;
  margin: 0 10px;
}
.tree-item {
  padding-left: 10px;
  &__field {
    max-width: 800px;
    &:not(:last-child) {
      border-bottom: 1px dotted #ccc;
    }
  }
  &__field-label {
    font-weight: bold;
  }
  &__field-value {
    float: right;
  }
}
</style>
